export default [
    {
        "fa": "fas fa-microphone",
        "value": "Your Body Podcast",
        "linkTo": "https://www.yourbodyshow.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "MammogramNow",
        "linkTo": "https://breastlink.com/services/mammogramnow"
    },
    {
        "fa": "fas fa-heartbeat",
        "value": "RadNet Cardiac Program",
        "linkTo": "https://www.radnet.com/cardiac-imaging"
    },
    {
        "fa": "fas fa-user-md",
        "value": "RadNet Prostate Screening",
        "linkTo": "https://prostate.radnet.com"
    },
    {
        "fa": "fas fa-lungs",
        "value": "RadNet Lung Cancer Screening",
        "linkTo": "https://radnetimaging.com/low-dose-lung-ct-scan/"
    },
    {
        "fa": "fas fa-hand-holding-medical",
        "value": "Your CHOICE in Imaging",
        "linkTo": "https://choice.radnet.com/"
    },
    {
        "fa": "fa fa-briefcase",
        "value": "RadNet Careers",
        "linkTo": "https://careers.radnet.com/careers-home"
    },
    {
        "fa": "fas fa-female",
        "value": "#MaddyTheMammoCoach",
        "linkTo": "https://breastlink.com/maddy/"
    },
    {
        "fa": "fas fa-play-circle",
        "value": "RadNet TV",
        "linkTo": "https://www.radnet.tv"
    },
    {
        "fa": "fa-solid fa-x-ray",
        "value": "X-Ray Hours & Live Wait Times",
        "linkTo": "https://xrayhours.com"
    },
    {
        "fa": "fas fa-globe",
        "value": "EastXRayHours.com",
        "linkTo": "https://eastxrayhours.com"
    },
    {
        "fa": "fa-solid fa-phone",
        "value": "Tulsa Oklahoma Contact Center",
        "linkTo": "https://radnettulsa.com/"
    },
    {
        "fa": "fas fa-user-md",
        "value": "Beverly Radiology Medical Group",
        "linkTo": "https://www.beverlyradiologymedicalgroup.com/"
    },
    {
        "fa": "fa-solid fa-puzzle-piece",
        "value": "Cancer Research Collaboration",
        "linkTo": "https://www.cancerresearchcollaboration.org/"
    }
]